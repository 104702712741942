import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { createClient } from '@supabase/supabase-js'
// import { SessionContextProvider } from ''

// const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
// const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_URLSUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey)
// supabase.auth.onAuthStateChange((event, session) => {
//   if(event ==='ACCESS_TOKEN_EXPIRED'){
//  console.log('expired');
//   }else
//   if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
//     console.log('sign_out');
//     // delete cookies on sign out
//     // const expires = new Date(0).toUTCString()
//     // document.cookie = `my-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`
//     // document.cookie = `my-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`
//   } else if (event === 'SIGNED_IN') {
//        console.log('sdd');
   
//     // const maxAge = 100 * 365 * 24 * 60 * 60 // 100 years, never expires
//     // document.cookie = `my-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
//     // document.cookie = `my-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
//   }else if(  event === 'TOKEN_REFRESHED'){
//     supabase.auth.signOut();

//   }
// })





console.log(supabase.auth.getSession());
supabase.auth.setSession({refresh_token:'opid'});
// supabase.auth.sess

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


