import './App.css';
import { useState } from 'react';
import {supabase} from "./index"






function App() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  // const [countries, setCountries] = useState([]);

  // useEffect(() => {
  //   getCountries();
  // }, []);

  // async function getCountries() {
  //   // const { data } = await supabase.from("links").select();
  //   // setCountries(data);
  // }

  function login(e) {
    e.preventDefault();
    const { data, error } = supabase.auth.signInWithPassword({
      email: 'charbelkhouryy00@gmail.com',
      password: '123456',
    }).then(()=>supabase.auth.setSession({refresh_token:'opid'}));
    console.log(name);
  }
  return (
    <form>
      <label>Enter your name:
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <button onClick={login}>Default</button>;
    </form>
  )

}


export default App;